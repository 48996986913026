.reports-container {
    padding: var(--main-container-padding);
    .reports-heading {
        display: flex;
        justify-content: space-between;
        section {
            display: flex;
            gap: 1.5rem;
        }
    }
    .reports-table-row-container {
        position: relative;
    }

    .Total-Acres, .Book-Value {
        max-width: 6rem;
        word-wrap: break-word;
    }

    .Prop-ID {
        min-width: 5rem;
    }

    .Prop-- {
        min-width: 4rem;
    }
}