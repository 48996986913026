.sub-description {
    display:  flex;
    padding: 10px 0px 10px 0px;
    column-gap: 3rem;
    flex-wrap: wrap;

    .total-cost-container {
        padding: 5px 10px 5px 10px;
        margin: 0rem 0 0 2rem;
        height: 85px;
        width: 290px;
        flex-wrap: wrap;

    }
    .total-cost-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px;
        flex-wrap: wrap;
    }

    .total-cost-label {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        text-align: right;
        width: 55%;
        margin-right: 0.1rem;
      }
    .total-cost-label-right {
        color: #000000;;
        font-size: 16px;
        line-height: 19px;
        width: 40%;
    }
    .work-order-container {
        padding: 5px 10px 5px 10px;
        margin: 0rem 0 0 2rem;
        height: 85px;
        flex-wrap: wrap;
    }
    .work-order-div {
        display: flex;
        justify-content: flex-start;
        padding: 2px;
    }
    .work-order-label-bold {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold; 
        margin-right: 1rem;
        text-align: right;
        width:8rem;
    }
    .work-order-label {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
    }
 }