
@import '../../App.scss';

.export-report-modal {
    width: 35% !important;
    .export-route-modal-header {
        h4 {
            font-weight: 400;
            font-size: 18px;
            margin-left: 5%;
        }
    }

    .export-report-modal-body {
        padding: 1rem 2.5rem 0rem 2.5rem;
        label {
            display: inline-block;
            margin-bottom: 1rem;
        }
        .export-report-radio-button-container {
            padding-top: 2rem;
            display: flex;
            gap: 30%;
            .radio-button {
                align-items: flex-start !important;
            }
        }
    }

    .export-report-modal-footer {
        @include modal-footer-with-cancel-confirm-button;
    }
}