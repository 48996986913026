@import '../../../App.scss';
.inventory {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .delete-icon {
        margin-top: 25px;
        cursor: pointer;
    }
}
.inventory-data-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    section {
        @include form-section;
         flex-basis: 10.52%;
         min-width: 8rem;
         row-gap: 0.8rem;
         margin-bottom: 0.2rem;
    }

    .acct-field {
        display: flex;
        flex-direction: column;
        flex-basis: 10.2%;
        justify-content: flex-end;
        margin-right: 25px;
        align-items: flex-start;
        font-family: Helvetica, sans-serif;
    }
    .acct-input {
        width: 85%;
    }

    .input-label {
        // margin-bottom: 12px;
        font-weight: 400;
    }

    .input-select-box {
        width: 85%;
        
    }
    
    .acct-input-amount {
        width: 85%;
        outline: none;
        padding: 1rem;
        border-radius: 4px;
        border: 1px solid #707372;
        text-align: right;
        font-family: Helvetica, sans-serif;
        appearance: none;
        text-overflow: ellipsis;
    }

    .error-input {
        border: 1px solid rgb(250, 103, 123);
    }
    .error-message {
        color: red;
        font-weight: normal;
        font-size: 13px;
    }
}