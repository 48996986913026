.loader-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    opacity: .9;
    justify-content: center;
    z-index: 1000;

    img {
        margin-top: 10%;
        height: 10rem;
    }
}