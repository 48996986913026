
.table-subheading-container {
    display: flex;
    gap: .5rem;
    align-items: center;
    padding: 1rem 0rem .5rem 0rem;
    font-size: 14px;

    .filter-button-container {

        .filter-modal-content {
            position: absolute;
            width: 100%;
            z-index: 1000;
        }

        button {
            border: none;
            background: none;
        }
    }
}