@import '../../../App.scss';

.transaction-container {

    padding: var(--main-container-padding);
    font-family: Helvetica, sans-serif, Arial;

    h2 {
        font-size: 20px;
    }

    .table-container {
        //font-size: 14px;
        @include scrollable-table
    }

}

