.pagination-container {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        border: none;
        height: 100%;
    }

    .pagination-btn {
        padding: 15px;
        margin: 0 5px;
        color: #101820;
        font-weight: bold;
        background-color: #fff;
        height: 100%;
        font-size: 16px;
        border-radius: 5px;
    }

    .pagination-ellipse {
        padding: 1px !important;
    }

    .pagination-btn-active {
        background-color: #101820;
        color: #fff;
    }

    .prev-btn, .next-btn {
        background-color: #fff;
    }

    .input {
        width: 3%;
        padding: 9px;
        margin: 0 5px;
        color: #101820;
        font-weight: bold;
        height: 100%;
        font-size: 16px;
        border-color: #101820;
    }

    .input-label {
        margin-right: 5px;
        margin-left: 15px;
        font-size: 16px;
    }
}