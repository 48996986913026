@import '../../../../App.scss';

.container {
    padding: var(--main-container-padding);
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
    .org-container {
        margin-top: 2rem;
    }

    .header-container {
        padding: 2px 0px 2px 0;
        display: flex;
        flex-direction: column;
    }

    .heading-label {
        color: #000000;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    .icon {
        justify-content: flex-start;
        column-gap: 2rem
    }
    section {
        @include form-section;
        flex-grow: 1;
        margin-right: 1.5rem;
    }
    .div-container {
        display: flex;
    }
    .input-section {
        margin-top: 1rem;
    }
    .input-box {
        box-sizing: border-box;
        border: 1px solid #707372;
        border-radius: 4px;
        background-color: #FFFFFF;
    }
   .delete-icon {
        margin-top: 25px;
        cursor: pointer;
    }
    .no-data-container {
        height: 5rem;
        display: flex;
        padding-top: 2rem;
        justify-content: center;
    }
    .company-input {
        border: 1px solid #707372;
        background: none;
    }
    
    .company-id {
        background: #e6e6e6;
        border: 1px solid #d5d5d5;
    }
}