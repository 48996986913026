@import "../../../../App.scss";

$bg-color-form: #f2f2f2;

.cost-information-container {
  @include form-container;
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

  background-color: $bg-color-form;

  .sub-section {
    @include form-subsection;

  }

  .column-section {
    flex-basis: calc(100% / 2 - 2rem);

  }

  .error-message {
    color: rgb(250, 103, 123);
    font-weight: bold;
  }

  .error-input {
    border: 1px solid rgb(250, 103, 123);
  }

  .line {
    border-bottom: 0.1rem solid black;
  }

  section {
    @include form-section;
    flex-basis: calc(100% / 2 - 2rem);
  }

  .read-input {
    background: #E6E6E6;
    border: 1px solid #D5D5D5;
    font-size: 15px;
}

.input-label-secondary {
  font-size: 14px;
  font-weight:400;
  color: black;
}

  #total,
  #propertyCost,
  #bookValue,
  #totalCost,
  #netProceeds,
  #grossProfit,
  #netGainLoss {
    font-weight: bold;
  }
}

@media only screen and (max-width: 900px) {
  .cost-information-container {
    section {
      flex-basis: calc(100% / 2 - 1.5rem);
    }
  }
}

@media only screen and (max-width: 650px) {
  .cost-information-container {
    section {
      flex-basis: calc(100% / 1 - 1.5rem);
    }
  }
}