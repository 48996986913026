.maintance-container {
    padding: var(--main-container-padding);

    .heading-container {
        display: flex;
        justify-content: space-between;
        padding: 5px 0px 5px 0px;
        padding-bottom: 3rem;
        flex-wrap: wrap;
        .export-btn {
            margin-left: 25px;
        }
    }

    .heading-maintenance {
        font-size: 24px;
        font-family: Helvetica, Arial, sans-serif;
    }

    .select-container {
        padding-top: 1rem;

        .maintenance-options-list {
            width: 25% !important;
            margin: 0;
            max-height: 100%;
        }
    }

    .label {
        font-size: 16px;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
    }

    .label-small {
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 12px;
        margin-left: 5rem;
    }

    .select {
        position: relative;
        box-sizing: border-box;
        border: 1px solid #707372;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
    }

    .data-container {
        margin-top: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #F8F8F8;
    }

    .select-div-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2rem;

        section {
            width: 30%;
        }
    }
    .search-container {
        margin-top: 1rem;
        box-sizing: border-box;
        border: 1px solid #979797;
        border-radius: 5px;
        background-color: #FFFFFF;
        width: 100%;
        height: 48px;

    }
    .search-input {
        background-image: none !important;
    }
    .search-result-list {
        right: 0 !important;
        left: 0 !important;
        width: 100% !important;
        max-height: none;
        // margin-top: 4.6rem
        // margin: 0;
    }

    .header-label-text {
        color: #000000;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 12px;
    }

    .column-div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 5rem;
    }

    .print-button {
        background-color: #D5D5D5;
    }
}

.maintenance-confirm-modal {
    width: 35% !important;
}