
@import '../../App.scss';

.route-email-modal {
    .route-modal-header {
        h4 {
            font-weight: 400;
            font-size: 18px;
            margin-left: 5%;
        }
    }

    .route-modal-body {
        padding: 1rem 2.5rem 0rem 2.5rem;
        .successfull-route-message {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 1.5rem;
        }
        .input-select-box {
            width: -webkit-fill-available;
            aspect-ratio: 7;
        }
        label {
            display: inline-block;
            margin-bottom: 1rem;
        }
    }

    .route-modal-footer {
        @include modal-footer-with-cancel-confirm-button;
    }
}