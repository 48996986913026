.checkbox-button {
    display: flex;
    align-items: flex-end;
    align-items: center;

    input {
        width: 28px;
        height: 28px;
    }

    label {
        font-size: 16px;
        margin-left: 10px;
    }
}