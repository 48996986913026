@import '../../../../App.scss';


.accounting-information-container {
    @include form-container;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

    .sub-section {
        @include form-subsection;
    }

    section {
        @include form-section;
        width: calc(100% /3 - 2rem);
    }

    .textarea-section {
        flex-basis: calc(100% /2 - 1.5rem);

        .legal-description-textarea,
        .comments-textarea {
            aspect-ratio: 7;
        }
    }

    .textarea-section1 {
        flex-basis: calc(100% /3 - 2rem);
    }

    textarea {
        resize: none;
      }
      textarea.fixed {
        height: 1rem !important;
      }

    .thomas-page-grid-section {
        display: grid;
        column-gap: 5%;
        grid-template-columns: repeat(2, 1fr);
    }

    .divided-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: 100%;

        .divided-left-section {
            display: flex;
            flex-wrap: wrap;
            column-gap: 3rem;
            flex-basis: calc(67% - 1.4rem);
            row-gap: 2rem;

            section {
                flex-basis: calc(100% /2 - 1.5rem);
            }
        }

        .divided-right-section {
            display: flex;
            flex-direction: column;
            flex-basis: 30%;
            row-gap: var(--add-transaction-row-gap);

            .select-if-applicable {
                text-decoration: underline;
                font-size: 16px;
            }
        }
    }

    .error-message {
        color: red;
        font-weight: normal;
        font-size: 13px;
    }

    .error-input {
        border: 1px solid rgb(250, 103, 123);
        font-size: 14px;
        margin-top: 0;
    }

    .read-input {
        background: #E6E6E6;
        border: 1px solid #D5D5D5;
        font-size: 14px;
    }

    input.read-checkbox:not(:checked) {
        background-color: #E6E6E6;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    input.read-checkbox:checked {
        accent-color: #E6E6E6 !important;
    }

}

@media only screen and (max-width: 900px) {
    .accounting-information-container {
        section {
            width: calc(100% / 2 - 1.5rem);
        }
    }
}

@media only screen and (max-width: 650px) {
    .accounting-information-container {
        section {
            width: calc(100% - 1.5rem);
        }

        .sub-section-divided {
            flex-basis: calc(100% - 1.6rem)
        }

        .textarea-section {
            flex-basis: calc(100% - 1.5rem);
        }
    }
}