@import "../../../App.scss";

.inventory-container {

    .error-message {
        color: red;
        font-weight: normal;
        font-size: 13px;
    }

    .err {
        border: 1px solid rgb(250, 103, 123) !important;
    }

    @include form-container;
    padding: 0rem 5.5rem;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

    .inventory-body-container {
        @include form-subsection;
    }

    .property-info-dropdown {
        position: relative;
    }

    section {
        @include form-section;
        width: calc(100% /3 - 2rem);
    }

    .read-input {
        // border: 1px solid #D5D5D5;
        font-size: 15px;
    }

    .read-input-read-only {
        background: #E6E6E6;
        border: 1px solid #D5D5D5;
        color:  #757575;
        font-size: 15px;
    }

    .read-only {
        background: #E6E6E6;
    }


    .thomas-page-grid-section {
        display: flex;
        justify-content: space-between;
        input {
            width: 98%;
        }

        input:last-child {
            margin-left: 1rem;
        }
    }

    .divided-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: 100%;

        .divided-left-section {
            display: flex;
            flex-wrap: wrap;
            column-gap: 3rem;
            flex-basis: calc(67% - 1.4rem);
            row-gap: 2rem;
            section {
                flex-basis: calc(100% /2 - 1.5rem);
            }
        }

        .divided-right-section {
            display: flex;
            flex-direction: column;
            flex-basis: 30%;
            row-gap: var(--add-transaction-row-gap);

            .select-if-applicable {
                text-decoration: underline;
                font-size: 16px;
            }

            section {
                display: flex;
                width: 100%;
                height: 100%;
            }

            .read-input {
                height: 100%;

            }
        }
    }

    .comments-div {
        display: flex;
        flex: 1 1 auto;

        section {
            flex: 1 1 auto;
        }

        .comments-textarea {
            height: 5rem;
            border: 1px solid Primary App Colors/Edison Gray #707372;
        }
    }

}

@media only screen and (max-width: 900px) {
    .inventory-container {
        section {
            width: calc(100% / 2 - 1.5rem);
        }
    }


}

@media only screen and (max-width: 650px) {
    .inventory-container {
        section {
            width: calc(100% - 1.5rem);
        }
    }
}