.predefined-report-card {
    padding: 3.5rem 3rem 2rem 6rem !important;

    .report-card-label {
        text-decoration: underline;
        font-weight: 600;
        font-size: 18px;
    }

    .reports-radio-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem 0rem;

        .first-column-container,
        .second-column-container,
        .third-column-container {
            display: flex;
            row-gap: 1rem;
            flex-direction: column;
            width: 30%;
        }

        .predefined-report-section {
            .radio-button {
                align-items: center !important;
            }

            .input-select-box {
                width: 80% !important;
                margin-top: .7rem !important;
            }

            .predefined-report-two-inputs {
                display: flex;
                width: 100%;
                column-gap: .6rem;
                flex-wrap: wrap;
                div {
                    flex-direction: column;
                }
            }
            .input-select {
                width:111px !important;
            }
            .error-style {
                width:121px !important;
                text-wrap: wrap;
                font-size: 14px;
            }
        }
    }

    .predefined-reports-button-section {
        display: flex;
        justify-content: flex-end;
        width: max-content;
        margin-top: 1rem;
        margin-left: auto;

        .apply-button {
            background: inherit;
            text-decoration: underline;
            color: #006269;
        }
    }
    .error-message {
        color: rgb(250, 103, 123);
        font-weight: bold;
        margin-top: 3px;
    }

    .error-input {
        border: 1px solid rgb(250, 103, 123);
    }

}