@import '../../../../App.scss';

.cross-reference-information-container {
    @include form-container;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
    
    .sub-section { 
        @include form-subsection;
        column-gap: 6rem;
        position: relative;
        height: 6rem;  
    }
    .read-input {
        background: #E6E6E6;
        border: 1px solid #D5D5D5;
        font-size: 15px
    }

    section {
        @include form-section;
        flex-basis: calc(100% /2 - 3rem);

        @include for-mid-screen-only {
            flex-basis: calc(100% /1 - 1.5rem);
        }
    }
}