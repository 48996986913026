.confirm-button, .cancel-button {
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding: .5rem 3rem;
    border-radius: 24px;
    min-width: 10rem;
    width: max-width;
    max-height: 3rem;
}

.button-disabled {
    background-color: #D5D5D5 !important;
}

.confirm-button {
    color: #101820;
    background-color: #FED141;
}

.cancel-button {
    background-color: inherit;
    display: flex;
    gap: .5rem;
}