.select-dropdown {
    background-image: url('../../../assets/DropdownArrow.svg');
    background-repeat: no-repeat;
    background-position: 95%;
    padding: 1rem 2rem 1rem 1rem !important;
    position: relative;
    font-size: 14px;
    .placeholder-dropdown {
        color: #757575 !important;
    }
}

.dropdown-result-list {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    max-height: 15rem;
    padding: 0;
    margin-top: 5.4rem;
    z-index: 1;
    list-style: none;
    overflow-y: scroll;
    overscroll-behavior: contain;

    li {
        padding: .5rem 1rem ;
        cursor: pointer;
		border-top: 1px solid #e6e6e6;
		font-size: 16px;
		line-height: 1.4;
        letter-spacing: .7px;

        &:hover {
            background-color: #F1F1F1;
            color: black;
        }
    }
    .dropbtn-selected {
        background-color: #F1F1F1;
        color: black;
    }
}
.dropdown-conatiner {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    border-radius: 0px 0px 5px 5px;
    // width: 100%;
    max-height: 15rem;
    padding: 0;
    margin-top: 5.4rem;
    z-index: 1;
    list-style: none;
    white-space: nowrap;
    overflow-y: auto;
    // overscroll-behavior: contain;

    table {
        // overflow-x:visible;
        border-collapse: collapse;
        width: 100%;

        th {
            position: sticky;
            text-align: left;
            padding: 3px 8px;
            font-size: 15px;
            font-weight: normal;
            top: 0;
            background-color: #757575;
            color: #F1F1F1;
            border-top: 1px solid #e6e6e6;
            line-height: 1.4;
            letter-spacing: .7px;
            width: 150px;
        }
        td {
            text-align: left;
            padding: 3px 8px;
            font-size: 14px;
            font-weight: normal;
            width: 150px;
            border-top: 1px solid #e6e6e6;
            line-height: 1.4;
            letter-spacing: .7px;
            cursor: pointer;
        }
        tbody tr:hover {
            background-color: #F1F1F1;
            color: black;
        }
    }
}