.view-transaction-form-container {
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

    .form-heading {
        padding: 0rem 5.5rem;
        font-size:medium;
        font-weight: normal;
    
    }

.add-transaction-footer {
        display: flex;
        justify-content: space-between;
    }

    .add-transaction-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 5rem 5rem 1rem 3rem;

        span {

            button {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }
        }

        .add-transaction-button-group {
           // flex-basis:70%;
            display: flex;
            gap: 1rem;
            justify-content: flex-end;

            .print-button {
                background-color: #D5D5D5;
            }

            .save-create-pdf-button {
                padding: .5rem 1rem;
            }
        }

    }
    

    
}
.view-transaction-cancel-button-group {
    display: flex;
    flex-wrap: wrap;
}


