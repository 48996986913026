

.county-section, .property-city, .property-state {
    position: relative;
}

.property-input {
    border: 1px solid #707372;
    background: none;
}

.heading {
    margin-top: 32px;
}

.property-number {
    background: #e6e6e6;
    border: 1px solid #d5d5d5;
}

.err {
    border: 1px solid rgb(250, 103, 123) !important;
}

.error-message {
    color: red;
    font-weight: normal;
    font-size: 13px;
}

.comments {
    height: 100%;
}