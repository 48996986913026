.welcome-box-footer-container {
    display: flex;
    justify-content: space-between;
    .welcome-box-footer {
        display: flex;
        align-items: center;
        input {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 5px;
        }
        label{
            font-size: 14px;
            margin-left: 5px;
        }
    }
}
.welcome-header {
    h4 {
        font-weight: 400;
    }
}

.modal {
    max-width: 30rem;
}

.top {
    top: 18% !important;
}
.error-div {
    padding: 8px 1px 1px 1px;
    font-size: 14px;
    li {
        padding:2px;
    }
}

.div-modal-body {
    display: flex;
    align-items: center;
    font-size: medium;

    .modal-icon {
        padding-right: 15px;
    }
}

.modal-footer-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}