@import '../../../../App.scss';

.container {
    background-color: #F8F8F8;
    margin-top: 1rem;

    .header-container {
        display: flex;
        flex-direction: column;
        // row-gap: 2rem;
    }

    .heading-label {
        color: #000000;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 20px;
        font-weight: bold;
    }

    section {
        @include form-section;
        margin-right: 1.5rem;
    }

    .input-label {
        color: #000000;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 16px;
        font-weight: normal;
    }

    .small {
        flex: 1;
    }

    .large {
        flex: 2;
    }

    .people-data-container {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 1rem;

    }

    .people-header-container {
        display: flex;
        flex: .99;
    }

    .people-item-container {
        display: flex;
        flex-grow: 1;
    }

    .delete-icon {
        margin-top: 25px;
        cursor: pointer;
    }

    .input-class {
        box-sizing: border-box;
        border: 1px solid #707372;
        border-radius: 4px;
        background-color: #FFFFFF;
        justify-content: center;
        padding-left: 1rem;
        width: 100%;
    }

    .input-box {
        padding: 0;
        flex: 1;
        width: 99%;
        padding-left: 1rem;
    }

    .add-another-button-container {
        margin-top: 8px;
        margin-bottom: 2px;
    }

    .no-data-container {
        height: 5rem;
        display: flex;
        padding-top: 2rem;
        justify-content: center;
    }

    .people-input {
        border: 1px solid #707372;
        background: none;
    }
    
    .people-id {
        background: #e6e6e6;
        border: 1px solid #d5d5d5;
    }
    
}