@import '../../../../App.scss';

.header-container {
    padding: var(--main-container-padding);
    display: flex;
    flex-wrap: nowrap;
    margin-top: 1rem;

    .category-header-container {
        display: flex;
        width: 99%;
    }
    section {
        @include form-section;
        margin-right: 1.5rem;
    }

    .small {
        flex:1;
    }
    .large {
        flex:2;
    }
    .header-with-icon {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 3px;
    }
    .input-label {
        color: #000000;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 16px;
        font-weight: normal;
        overflow: visible;
    }
    .reverse {
        rotate: 180deg;
    }
}