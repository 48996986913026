$bg-secondary: #41474E;

.properties-container {
    padding: var(--main-container-padding);
    font-family: Helvetica, sans-serif, Arial;
    min-height: 100%;

    .properties-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .properties-btn-style {
        padding: 12px 26px;
    }

    .add-properties-btn-style {
        padding: 12px 36px;
        margin-right: 10px;
    }

    .rows {
        padding: 24px 0 16px 0;

        span {
            margin-left: 8px;
        }
    }

    .Total-Acres, .Book-Value {
        max-width: 6rem;
        word-wrap: break-word;
    }

    .Prop-ID {
        min-width: 5rem;
    }

    .Prop-- {
        min-width: 4rem;
    }

    .button-container {
        display: flex;
        align-items: center;
    }
}