.add-transaction-form {
	margin-top: 3rem;
	font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

	.add-transaction-heading {
		padding: 0rem 5.5rem;
	}

	.add-transaction-heading,
	.add-transaction-footer {
		display: flex;
		justify-content: space-between;
	}

	.add-transaction-footer {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0rem 6.5rem 2rem 5.5rem;

		span {
			button {
				display: flex;
				gap: 1rem;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
			}
		}

		.add-transaction-button-group {
			flex-basis: 57%;
			display: flex;
			gap: 1rem;
			justify-content: flex-end;

			.create-pdf-button {
				padding: .5rem 1rem;
			}
		}

	}
}

.modal-wrapper {
	z-index: 20;
	.transaction-pdf-modal {
		width: 80% !important;
		height: 100vh !important;
		top: 1% !important;
		.modal-container {
			margin: 0px !important;
		}
	}
}

