
.inventory-acct-data-container {
    padding: var(--main-container-padding);
    .inventory-acct-heading {
        margin-bottom: 12px;
        font-size: 20px;
        font-size: 700;
    }

    .Xfer-Date {
        width: 100px;
    }

    .aact-data {
        width: 100%;
    }

    .add-another-button-container {
        margin-top: 8px;
    }

    .error-input {
        border: 1px solid rgb(250, 103, 123);
    }
    .error-message {
        color: red;
        font-weight: normal;
        font-size: 13px;
    } 
}
