.form-container {
    margin-top: 3rem;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

    .form-heading {
        padding: 0rem 5.5rem;
        font-size:medium;
        font-weight: normal;
    
    }

.add-transaction-footer {
        display: flex;
        justify-content: space-between;
    }

    .add-transaction-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 5rem 5rem 1rem 3rem;
        
        .dlt-btn-container {
            display: flex;
        }

        .dlt-btn-container {
            margin-left: 10px;
        }

        span {
            button {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }
        }

        .add-transaction-button-group {
           // flex-basis:70%;
            display: flex;
            gap: 1rem;
            justify-content: flex-end;

            .print-button {
                background-color: #D5D5D5;
            }

            .save-create-pdf-button {
                padding: .5rem 1rem;
            }
        }
    }   
}

.close-btn {
    position: absolute;;
    top: 2rem;
    right: 2rem;
    height: fit-content;
    cursor: pointer;
    svg {
        height: 28px;
        width: 28px;
    }
}
.modal-wrapper {
    z-index: 999999;
}


