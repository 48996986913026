.main-header {
    background-color: #000000;
    padding: 14px 0;
    position: sticky;
    z-index: 10;
    .reis-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

    
