:root {
    --main-container-padding: 40px 70px 0px 92px;
    --add-transaction-row-gap: 2rem;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.sub-heading {
    font-weight: 600;
    font-size: 20px;
    color: black;
    margin: 0;
}

.dropdown-section {
    position: relative;
}

.delete-row-icon-container {
    position: absolute;
    left: 100%;
    margin-left: 1rem;
    top: 50%;
    cursor: pointer;
}

.input-label {
    font-size: 16px;
    font-weight: 600;
    color: black;
}

td {
    padding: 5px;
    line-height: 1.2rem;
    max-width: 15rem;
}

tr {
    max-height: 5rem !important;
}

.empty-data-message {
    padding: 1rem;
    text-align: center;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.route-button {
    border: 2px solid black;
    background-color: white;
}

::-webkit-scrollbar {
    width: .3rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(161, 155, 155);
    border-radius: 5px;
}

.input-select-box {
    border: 1px solid #707372;
    border-radius: 4px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    padding: 1rem;
    max-width: 100%;
    text-overflow: ellipsis;
    appearance: none;

    &:focus {
        outline: none;
    }

    &::placeholder {
        opacity: .8;
    }
}

input[type=radio] {
    &:focus {
        outline: none;
    }
}

body,
h1,
h2,
h3,
h4,
input,
p {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
}

button {
    cursor: pointer;
}

.add-another-button-container {
    margin-top: calc((var(--add-transaction-row-gap) - .3rem) * -1)
}

.add-another-button {
    background: inherit;
    text-decoration: underline;
    color: #006269;
    font-size: 18px;
    padding: 1.5rem 0rem;
    text-align: justify;
}

.error-container {
    display: flex;
    height: 70vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label-no-data-found-with-y {
        height: 19px;
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.19px;
        line-height: 19px;
    }
    .please-try-again-wit {
        height: 19px;
        color: #757575;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        letter-spacing: -0.19px;
        line-height: 19px;
      }
}

@mixin form-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 1.7rem;
    padding: 3rem 5.5rem;
}

@mixin scrollable-table {
    max-height: 27rem;
    overflow: auto;

    thead {
        position: sticky;
        top: 0;
    }
}

@mixin form-subsection {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: var(--add-transaction-row-gap);
}

@mixin form-section {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

@mixin modal-footer-with-cancel-confirm-button {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

@mixin for-phone-only {
    @media (max-width: 650px) {
        @content;
    }
}

@mixin for-mid-screen-only {
    @media (max-width: 750px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}