$bg-secondary: #41474E;

.transactions-table-container {
    font-size: 14px;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 60vh;

    th {
        color: #FFF;
        background: $bg-secondary;
        font-weight: normal;
        padding: 4px;
        text-align: left;
        .th-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        position: sticky !important;
        top: 0;
    }

    th button {
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #fff;
        border-right: 0px;
        background: transparent;
    }

    .sortable {
        position: relative;
    }
    .svg-container {
        flex: 1;
        text-align: right; 
        align-content: center;
        cursor: pointer;
    }

    .arrow {
        flex: 1
    }
    .up-arrow{
        rotate: 180deg;

    }

    table {
        width: 100%;

        td {
            font-size: 14px;
            a {
                color: #006269;
                text-decoration: underline;
            }
        }
    }

    .td-right {
        text-align: right;
    }

    tr:nth-child(even) {
        background: #EEECEC;
    }
    
}
