@import '../../../../App.scss';

.property-activity-data-container {
    @include form-container;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
    
    .sub-section { 
        @include form-subsection;
        column-gap: .5rem;
        position: relative;
        padding-top: .5rem;

        .delete-row-icon-container {
            top: 60%
        }
    }

    section {
        @include form-section;
         flex-basis: 10.52%;
         min-width: 8rem;
    }

    .add-transaction-button-container {
        padding: 1rem 0rem;
        width: fit-content;
    }

    .total-acres-amount-container {
        .sub-section {
            column-gap: 3rem;
            section {
                flex-basis: calc(100% / 2 - ( 3rem / 2 ));
            }
        }
    }
    .error-input {
        border: 1px solid rgb(250, 103, 123);
    }
    .error-message {
        color: red;
        font-weight: normal;
        font-size: 13px;
    }

    .read-input {
        background: #E6E6E6;
        border: 1px solid #D5D5D5;
        font-size: 15px;
    }
    .right-align {
        //direction: rtl;
        text-align: right;
    }
}


@media (max-width: 750px) { 
    .property-activity-data-container {
        section {
            flex-basis: 32.5%
        }
    }
 }

 @media (max-width: 650px) {
    .property-activity-data-container {
        section {
            flex-basis: 100%
        }
    }
 }