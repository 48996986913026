.inventory-info-container {
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
    padding: var(--main-container-padding);
    font-size: medium;
    font-weight: normal;

    .inventory-info-heading {
        margin-top: 24px;
        margin-bottom: 0px;
        padding: 0px;
        font-size: 20px;
        font-weight: 700;
    }

    .read-input {
        background: #E6E6E6;
        border: 1px solid #D5D5D5;
        color:  #757575;
        font-size: 15px;
    }

    .input-label {
        margin-bottom: 1rem;
        font-weight: 400;
    }

    .property-amount-details {
        display: flex;
        margin-top: 2rem;

        .input-block {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
        }

        .total-amount-input {
            margin-left: 2rem;
        }
    }

    .property-remarks {
        display: flex;
        margin-top: 2rem;

        .remarks-txt-area {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;

            .read-input {
                height: 100px;
            }
        }

        .acq-doc-comments {
            margin-left: 2rem;
        }

        .acq-doc-txt {
            background: #fff;
            color: #000000;
        }
    }

    .property-details {
        display: flex;
        margin-top: 2rem;

        .input-block {
            display: flex;
            flex-direction: column;
        }

        .property-name-input {
            flex-basis: 40%;
        }

        .property-id-input,
        .parcel-input,
        .acq-doc-input {
            flex-basis: 20%;
        }

        .property-id-input,
        .parcel-input,
        .property-name-input {
            margin-left: 2rem;
        }
    }
}