@import '../../../../App.scss';

.add-transaction-modal {
    max-width: 37rem;
}

.add-transaction-modal-header {
    h4 {
        font-weight: 400;
    }
}

.add-transaction-modal-content {

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .modal-left-content,
    .modal-right-content {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .heading {
            font-weight: 700;
        }

        label {
            font-size: 16px;
        }
    }
}

.add-transaction-modal-footer {
    @include modal-footer-with-cancel-confirm-button
}

.booked-transaction-modal-content {
    display: flex;
}