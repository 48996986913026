$bg-primary:  #000000;
$bg-secondary: #41474E;
$edison-yellow: #FED141;

.sub-header-container {
    display: flex;
    padding: 0px 70px;
    position: sticky;
    z-index: 10;
    top: 0;
    background: $bg-secondary;

    .reis-nav {
        display: flex;
        flex-wrap: wrap;
        margin: 0px;
        padding: 0px;
        flex: 65%;
    }

    .reis-nav li, .settings-menu-container p {
        color: $edison-yellow;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 16px; 
    }

    .reis-nav li {
        list-style: none;
        margin: none;
        padding: 30px 24px;
        text-decoration: none;
        cursor: pointer;
    }

    .search-box-container {
        flex: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .settings-container {
        display: flex;
        align-items: center;
        margin-left: 12px;
        position: relative;
        .settings-icon {
            cursor: pointer;
        }
    }

    .settings-menu-container {
        display: block;
        padding: 12px;
        position: absolute;
        top: 100%;
        right: 2%;
        background: $bg-secondary;
        p {
            margin: 0px;
        }

        .logout {
            padding: 22px 24px 12px 24px;
            cursor: pointer;
        }

        .maintenance-menu {
            border-bottom: $edison-yellow solid 1px;
            padding: 12px 24px 22px 24px;
            cursor: pointer;
        }

        .maintenance-menu, .logout {
            width: 130px;
        }
    }
}

ul .active {
    background-color: $bg-primary; 
}

