.radio-button {
    display: flex;
    align-items: flex-end;

    input {
        width: 22px;
        height: 22px;

        &:checked {

            &::before {
                content: url('../../../assets/CheckBox.svg');
                display: block;
            }
        }
    }

    label {
        margin-left: 10px;
    }
}