@import '../../../../App.scss';

.footer-container {
    padding: var(--main-container-padding);
    .text-container {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
        justify-content: space-between;
        align-items: center;
    }
    .input-label-optional {
        color: #000000;
        font-size: 15px;
        font-weight: normal;
    }
    .pagination-container {
        display: flex;
        column-gap: 1em;
        text-align: justify;

        input {
            width: 6%;
        }
    }
}