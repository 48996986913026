$bg-secondary: #41474E;

.transactions-container {
    padding: var(--main-container-padding);
    font-family: Helvetica, sans-serif, Arial;
    min-height: 100%;

    .transactions-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .transaction-btn-style {
        padding: 12px 28px;
    }

    .rows {
        padding: 24px 0 16px 0;

        span {
            margin-left: 8px;
        }
    }

    .Record-No {
        min-width: 6rem;
    }

    .dlt-warning-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 1rem;
    }

    .dlt-warning {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 18px;
        border: 2px solid #FBB04C;
        background-color: rgba(251,176,76,0.1);
        border-radius: 10px;
    }

    .warning-text {
        margin-left: 15px;
    }
}