.search-container {
    display: flex;
    align-items: center;
    position: relative;
    background: white;
    padding: 0px 16px;
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
    height: 3rem;
    .select-dropdown {
        border-left: 0;
        border-bottom: 0;
        border-top: 0;
        width: 70%;
        height: -webkit-fill-available !important;
        margin-right: 10px;
        border-radius: 0px !important;
        font-size: 16px;
    }
    .search-options-list {
        width: 50% !important;
        font-size: 16px;
    }
    .search-result-list {
        right: 0 !important;
        left: 35% !important;
        width: 65% !important;
        font-size: 16px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .search-input {
        background-image: none !important;
        cursor: pointer;
        font-size: 16px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .dropdown-result-list {
        left: 0;
        top: -2.36rem !important;
        font-size: 16px;
    }

    .search-options-list {
        max-height: none;
        font-size: 16px;
    }

    .search-result-list {
        max-height: 20rem;
        font-size: 16px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .search-input {
        width: 100%;
        border-style: none;
        font-size: 16px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .search-input:focus {
        outline: none;
    }

    .search-close-icon {
        margin-right: 8px;
    }

    .search-close-btn {
        border: none;
        background-color: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .search-filter {
        cursor: pointer;
        font-size: 16px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

