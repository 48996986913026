.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    justify-content: center;

    .modal-container {
        position: absolute;
        top: 25%;
        height: fit-content;
        width: 50%;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    }

    .modal-header{
        background-color: black;
        color: white;
        padding: 1.5rem;
    }

    .modal-body {
        background-color: white;
        color: black;
        padding: 1.5rem;
    }

    .modal-footer {
        background-color: white;
        color: black;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
}

.modal-hidden {
    display: none;
}